import React from 'react';
import './HotTubs.scss';
import CrownSummit from '../../media/images/crown-summit.jpeg';
import VectorV84 from '../../media/images/vector-v84.jpeg';
import MarquisPoolSide from '../../media/images/marquis-poolside.jpeg';
import InGroundOne from '../../media/images/raised-inground-one.jpg';

const HotTubs = () => {
    return (
        <div className='spa'>
            <div className="spa__header">
                <h2 className="spa__header--title">Hot Tubs</h2>
            </div>

            <section className="spa__above-ground">

                <div className="spa__container">
                    <h3 className="spa__container--title">Marquis Hot Tubs - The Ultimate Spa Experience</h3>
                    <p className="spa__container--description">Our patented high-flow, low pressure therapy system delivers the best hydrotherapy in the world. The average two-pump hot tub can push water at 320 gallons per minute / 1211 liters per minute for ultimate therapy benefits. With High Output Therapy (H.O.T.) Zones or Vector-Optimized Laminar Therapy (V.O.L.T.) systems you have world class therapy that no other manufacturer can touch. From rehab to training or simply soaking in warm water after a workout: Marquis delivers</p>
                </div>

                <div className="spa__range">
                    <img src={CrownSummit} alt="Marquis Crown Line Summit Seating" className='spa__range--img'/>
                    <img src={VectorV84} alt="Marquis Vector Line V84 Seating" className='spa__range--img'/>
                </div>

                {/* <div className="spa__container">
                    <h3 className="spa__container--title">MicroSilk Oxygen Skin Therapy</h3>
                    <p className="spa__container--description">MicroSilk rejuvenates, repairs and re-invigorates skin for a youthful, refreshed appearance. Increased oxygen levels result in better circulation and cellular respiration, which in turn leaves the skin glowing and radiant due to rapid skin cell regeneration. Soaking in a MicroSilk cloud improves serotonin levels, increases skin metabolism and deep cleans pores to remove impurities.</p>
                </div> */}

                <div className="spa__container">
                    <h3 className="spa__container--title">Unmatched Pristine Water</h3>
                    <p className="spa__container--description">No other manufacturer has a system that maintains such high water quality. Not only that, the water maintenance system is standard. Our high-flow system cleans the water at 80 gallons per minute / 302 liters per minute pulling water through our proprietary vortex skimmer. That is complete water turnover 60 to 100 times a day. With the addition of our in-line system you have ultimate sanitization and water treatment controlled by a completely customizable schedule.</p>
                </div>

                    <img src={MarquisPoolSide} alt="Marquis Spa PoolSide" className='spa__img'/>

                <div className="spa__container">
                    <h3 className="spa__container--title">Exclusive New Pool Savings</h3>
                    <p className="spa__container--description">When you work with Jameson for your new pool or newly renovated pool, you are welcomed to join in on the savings. We are proud to offer exclusive discounts to our new and renovated pool customers. Allowing you to really build and choose every space of your backyard.</p>
                </div>

            </section>

            <section className="spa__inground">
                <div className="spa__inground--header">
                    <img src={InGroundOne} alt="" className="spa__inground--header-img" />
                </div>

                <div className="spa__container">
                    <h3 className="spa__container--title">In-Ground Hot Tubs</h3>
                    <p className="spa__container--description">Incorporating an inground fiberglass hot tub into your pool design offers a visually appealing clean-line, resort-like aesthetic that elevates the overall ambiance of your outdoor space. The sleek and seamless design of fiberglass hot tubs contributes to a modern and luxurious look, reminiscent of high-end resorts. Furthermore, these hot tubs are designed to provide a deep, soaker-style experience, allowing for full immersion and a more relaxing and therapeutic soak, enhancing the feeling of a private retreat right in your backyard.</p>
                </div>
            </section>

        </div>
    );
};

export default HotTubs;