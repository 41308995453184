import React from 'react';
import './QualityBuildPage.scss';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GuniteBottomVideo from '../../media/videos/gunite_drone_video.mp4';
import GuniteBackfillVideo from '../../media/videos/Gunite-Backfill.mp4';
import Plumbing from '../../media/images/2in-plumbing.jpg';
import Liners from '../../media/images/liner-header.jpg';
import StepAndBench from '../../media/images/step-and-bench-combo.jpg';
import WeddingStepBench from '../../media/images/wedding-cake-steps-and-bench.jpg';
import Bench from '../../media/images/bench.png';
import Sunledge from '../../media/images/sunledge.jpg';

const QualityBuildPage = () => {
    return (
        <div className='quality-build'>
            <section className="quality-build__header">
                <h2 className="quality-build__header--title">Quality Built Pools</h2>
            </section>

            <section className="materials__header"></section>

            <section className="materials__gunite">
                <h4 className="materials__gunite--title">Extra Strength Reinforced</h4>
                <h6 className="materials__gunite--subtitle">Gunite Pool Bottom</h6>
                <div className="materials__gunite--container">
                    <video src={GuniteBottomVideo} controls muted loop autoPlay className='materials__gunite--video'></video>
                    <p className="materials__gunite--about">
                        We use a special concrete Gunite which is applied to the pool bottom and base of the walls using a spray gun. Gunite is an even stronger concrete mix, which eliminates seams in the concrete making it far more durable over traditional concrete and fiberglass.
                    </p>
                </div>

                <h6 className="materials__gunite--subtitle">Gunite Pool Walls & Plumbing</h6>
                <div className="materials__gunite--container">
                    <video src={GuniteBackfillVideo} controls muted loop autoPlay className='materials__gunite--video'></video>
                    <p className="materials__gunite--about">
                        Gunite Concrete is applied in multiple areas of the construction including behind the pool walls to form a complete surround of the wall base, as well as inside your pool steps. We use Steel and Gunite to build the longest lasting structures much like an apartment building.
                    </p>
                </div>
            </section>

            <section className="materials__plumbing">
                <h4 className="materials__plumbing--title">Larger Plumbing</h4>
                <img src={Plumbing} alt="Larger 2 inch plumbing for increased water flow" className='materials__plumbing--img' />
                <div className="materials__plumbing--container">
                    <p className="materials__plumbing--about">
                        Every Jameson Pool is built and plumbed using 2 inch pipes, allowing for 65% more water flow over the standard 1.5 inch pipes. More water is moved and circulated with the same amount of energy, meaning fewer hours your pump has to run.
                    </p>
                </div>
            </section>

            <section className="materials__liner">
                <h4 className="materials__liner--title">Top Quality Liners</h4>
                <img src={Liners} alt="GLI Reef Liner" className='materials__liner--img' />
                <div className="materials__liner--container">
                    <p className="materials__liner--about">
                        GLI Vinyl Liners offer over 100+ colors and patterns to choose from, lasting up to 12+ years. They also allow for you to easily overhaul the look of your pool, as it is a less than 1-day job to install a new liner.
                    </p>
                    <p className="materials__liner--about">
                        A Fiberglass pool comes in standard layouts and colors, you cannot get an 8-foot deep pool on a 16x32 as they are preset designs. The modular design of a fiberglass pool limits your options to maximize your space and interior layout. A Vinyl Pool allows you and us to build the steps, depths, benches, and ledges how you want and in the color you want.
                    </p>
                </div>
            </section>

            <section className="materials__under-liner-steps">
                <h4 className="materials__under-liner-steps--title">Customize Your Pool with Under-Liner Steel Steps</h4>
                <Carousel className="materials__under-liner-steps--carousel">
                    <div>
                        <img src={StepAndBench} alt="Step and Bench Combo" />
                        <p className="legend">Step and Bench Combo</p>
                    </div>
                    <div>
                        <img src={WeddingStepBench} alt="Wedding Cake Steps and Bench" />
                        <p className="legend">Wedding Cake Steps and Bench</p>
                    </div>
                    <div>
                        <img src={Bench} alt="Bench" />
                        <p className="legend">Bench</p>
                    </div>
                    <div>
                        <img src={Sunledge} alt="Sun Ledge" />
                        <p className="legend">Sun Ledge</p>
                    </div>
                </Carousel>
                <div className="materials__under-liner-steps--container">
                    <section className="customization">
                        <h5 className="materials__under-liner-steps--subtitle">Explore More Customization Options</h5>
                        <p className="materials__under-liner-steps--about">
                            Under-liner steel steps offer unparalleled flexibility in designing your pool space. With these steps, you have the freedom to customize the layout and aesthetic of your pool to suit your unique preferences. Whether you prefer a classic design or something more contemporary, under-liner steel steps provide the perfect foundation.
                        </p>
                    </section>
                    <section className="modern-look">
                        <h5 className="materials__under-liner-steps--subtitle">Modern and Clean Look</h5>
                        <p className="materials__under-liner-steps--about">
                            Achieve a sleek, modern, and clean look with under-liner steel steps. These steps seamlessly blend with the pool liner, creating a visually appealing and cohesive appearance. The smooth, uninterrupted lines enhance the overall elegance of your pool, making it a standout feature in your backyard.
                        </p>
                    </section>
                    <section className="comfort-relaxation">
                        <h5 className="materials__under-liner-steps--subtitle">Comfort and Relaxation</h5>
                        <p className="materials__under-liner-steps--about">
                            For added comfort, consider lining the steel steps with memory foam. This option transforms your steps into a comfortable bench where you can sit and relax. Whether you're lounging by the poolside or taking a break from swimming, the memory foam lining provides a soft and inviting surface.
                        </p>
                    </section>
                    <section className="lounging-area">
                        <h5 className="materials__under-liner-steps--subtitle">Benches and Sun Ledges for Lounging Areas</h5>
                        <p className="materials__under-liner-steps--about">
                            Transform your pool into a personal oasis with custom-built benches and sun ledges. Steel steps can be designed to include these features, providing you with luxurious lounging areas inside your pool. Benches offer comfortable seating, perfect for socializing or relaxing, while sun ledges provide shallow areas where you can lay back and soak up the sun. These additions create versatile spaces for both relaxation and fun, enhancing the overall pool experience.
                        </p>
                    </section>
                </div>
            </section>

            <section className="materials__experience">
                <h4 className="materials__experience--title">Experienced Installation</h4>
                <h3 className="materials__experience--subtitle">
                    <CountUp end={3000} duration={4} suffix="+ pools built to date" delay={0}>
                        {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp>
                </h3>
                <div className="materials__experience--container">
                    <p className="materials__experience--about">
                        We have installed and renovated well over 3,000 pools since 1994 while being leaders in pushing the design of pools. We were the first to install pools with the steps and memory foam under the liner. We were also one of the first to build modern rectangular pools with benches and built-in steps with comfort foam padding.
                    </p>
                    <p className="materials__experience--about">
                        Our teams have the experience to solve any problem thrown our way in a quick and efficient manner. We are proud of our craftsmanship and relationships with our clients.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default QualityBuildPage;
