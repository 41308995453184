import React from 'react';
import './SafetyCover.scss';
import BlackCover from '../../media/images/cover-black.jpeg';
import BlueCover from '../../media/images/cover-blue.jpeg';
import BeigeCover from '../../media/images/cover-ten.jpeg';
import GreenCover from '../../media/images/cover-green.jpeg';
import GreyCover from '../../media/images/cover-grey.jpeg';

const SafetyCover = () => {
    return (
        <div className='safety-cover'>
            <div className="safety-cover__header">
                <h2 className="safety-cover__header--title">Safety Is Standard</h2>
            </div>

            <section className="safety-cover__description">
                <h3 className="safety-cover__description--title">What is a Safety Cover?</h3>
                <div className="safety-cover__description--container">
                    <p className="safety-cover__description--sentence">A safety cover is a mesh cover that goes over your pool during the off-season months and is anchored through drilling into the concrete deck. 99% of our new pools opt to go with the standard Yard-Guard Safety cover as it is the strongest option to protect your family and pool.</p>
                    <p className="safety-cover__description--sentence">Your safety cover will be able to hold up to 485lbs and comes with a 10-year warranty on the mesh. We have routinely ehard stories of trees falling onto the cover and no damage was done. Safety Covers also offer a lower cost of opening and closing your pool due to less time to remove and clean the cover.</p>
                </div>
            </section>

            <section className="safety-cover__colors">

                <h4 className="safety-cover__colors--title">5 Colors To Choose From:</h4>

                <div className="safety-cover__colors--gallery">
                    <div className="safety-cover__colors--container beige">
                        <h6 className="cover__title">Beige</h6>
                        <img src={BeigeCover} alt="" className="cover__img" />
                    </div>
                    <div className="safety-cover__colors--container black">
                        <h6 className="cover__title">Black</h6>
                        <img src={BlackCover} alt="" className="cover__img" />
                    </div>
                    <div className="safety-cover__colors--container blue">
                        <h6 className="cover__title">Blue</h6>
                        <img src={BlueCover} alt="" className="cover__img" />
                    </div>
                    <div className="safety-cover__colors--container green">
                        <h6 className="cover__title">Green</h6>
                        <img src={GreenCover} alt="" className="cover__img" />
                    </div>
                    <div className="safety-cover__colors--container grey">
                        <h6 className="cover__title">Grey</h6>
                        <img src={GreyCover} alt="" className="cover__img" />
                    </div>
                </div>
            </section>
            
        </div>
    );
};

export default SafetyCover;