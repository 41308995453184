import React from 'react';
import SiteProtection from '../../media/images/site-protection.jpg';
import TreeHoarding from '../../media/images/tree-hoarding.jpeg';
import SmallAccess from '../../media/videos/smallAccess.MOV';
import MediumAccess from '../../media/videos/medium-access.MOV';
import LargeAccess from '../../media/videos/large-access.MOV';
import ExcavationVideo from '../../media/videos/excavation-drone.MP4';
import './Excavation.scss';

const Excavation = () => {
    return (
        <div className='excavation'>
            <div className="excavation__header">
                <h2 className="excavation__header--title">Dive Into Excavation</h2>
            </div>

            <div className="excavation__video">
                <video src={ExcavationVideo} alt="" className="excavation__video--video" controls muted loop autoPlay/>
            </div>

            <section className="excavation__protection">
                <div className="excavation__protection--header">
                    <h4 className="excavation__protection--title">Site Protection</h4>
                </div>

                <div className="protection__tile">
                    <img src={TreeHoarding} alt="Orange Snow Fence and Wood tree protection around tree" className="protection__tile--img" />
                    <h5 className="protection__tile--title">Tree Hoarding</h5>
                    <p className="protection__tile--description">Every city requires the trees on your yard to be protected during the entire construction process. We must construct a fence around trees based on an arborist report before anywork can begin. We may also be required to put mulch on the access to reduce damage to tree roots.</p>
                </div>

                <div className="protection__tile">
                    <img src={SiteProtection} alt="Multiple Wooden and Plastic plates for driveway protection" className="protection__tile--img" />
                    <h5 className="protection__tile--title">Steel & Plastic Plates</h5>
                    <p className="protection__tile--description">Our machines are very heavy, weighing in excess of 2 tonnes and will make over 60 passess. This can cause damage to driveways and the ground itself. We will take the precaution of placing heavy duty plates and at times mulch to add protection to your driveway and yard.</p>
                </div>

            </section>

            <section className="excavation__access">
                <div className="excavation__access--header">
                    <h4 className="excavation__access--title">How does Access Effect The Project?</h4>
                </div>

                <div className="excavation__access--container">
                    <p className="excavation__access--description">The size of the access to the yard directly affects the type and size of excavation equipment that can be utilized for the project. A larger access area allows for the use of larger excavation machines, which have a higher capacity for moving dirt in a shorter amount of time compared to smaller machines.</p>
                    <p className="excavation__access--description">There are options availabe to increase the access size and lower the costs. These do come with additional expenses but are not significant such as removal of your A/C unit if it is on the access side. We can also remove pieces of the fence, with your neighbors approval, to fit a larger machine through the access.</p>
                </div>

                <div className="excavation__access--gallery">
                    <div className="access__tile">
                        <h5 className="access__tile--title">Small Access</h5>
                        <video src={SmallAccess} alt="" className="access__tile--img" controls muted loop autoPlay/>
                        <ul className="access__tile--list">
                            <li className="access__tile--item"><u>Access Size:</u> 4 feet or less</li>
                            <li className="access__tile--item"><u>Machine Size:</u> 3 feet</li>
                            <li className="access__tile--item"><u>Average Time:</u> 1.5 - 2 days</li>
                        </ul>
                    </div>

                    <div className="access__tile">
                        <h5 className="access__tile--title">Medium Access</h5>
                        <video src={MediumAccess} alt="" className="access__tile--img" controls muted loop autoPlay/>
                        <ul className="access__tile--list">
                            <li className="access__tile--item"><u>Access Size:</u> 5 feet</li>
                            <li className="access__tile--item"><u>Machine Size:</u> 4.5 feet</li>
                            <li className="access__tile--item"><u>Average Time:</u> 1 - 1.5 days</li>
                        </ul>
                    </div>

                    <div className="access__tile">
                        <h5 className="access__tile--title">Large Access</h5>
                        <video src={LargeAccess} alt="" className="access__tile--img" controls muted loop autoPlay/>
                        <ul className="access__tile--list">
                            <li className="access__tile--item"><u>Access Size:</u> 6+ feet</li>
                            <li className="access__tile--item"><u>Machine Size:</u> 5+ feet</li>
                            <li className="access__tile--item"><u>Average Time:</u> 1 day</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Excavation;