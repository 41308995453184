import React from 'react';
import './Equipment.scss';
import OmniLogic from '../../media/images/Omnilogic.png';
import Heater from '../../media/images/heater.png';
import Cleaner from '../../media/images/cleaner.png';
import Filter from '../../media/images/filter.png';
import Pump from '../../media/images/variable-pump.png';
import SaltCell from '../../media/images/salt-cell.jpeg';
import Lights from '../../media/images/lighting.png';

const Equipment = () => {


    return (
        <div className='equipment'>
            <div className="equipment__header">
                <h2 className="equipment__title">Hayward Expert Line Equipment</h2>
            </div>

                <section className="equipment__container">

                    <p className="equipment__container--about">Every Jameson Pool comes with the top of line automated pool equipment from Hayward. The Expert Line equipment package is one of the most simple systems to use and requires little maintenance on your part. We are confident in the product and our work, we are able to offer an extended warranty of 3-years over the standard 1-year warranty each piece of equipment comes with.</p>
                    <p className="equipment__container--about">Our team is responsible for hooking up the equipment to the plumbing lines and setting up the system programs itself. A Gas Tradesman, whether one we reccommend or your own, is responsible for runing the gas line and connecting your heater. Finally an Electrician, one of our partners or your own, is responsible for wiring the system. Both of these processes require individuals with licenses to perform. We will cordinate the scheduling to install and connect the equipment.</p>

                   <div className="equipment__tile">
                        <div className="tile__container">
                            <img src={OmniLogic} alt="Hayward OmniLogic Pool Automation" className='tile__img'/>
                            <h4 className="tile__category">Automation</h4>
                            <h2 className="tile__product">Hayward OmniLogic</h2>
                            <p className="tile__description">Control your pool, spa and backyard anywhere in the world. Complete control over Lights, Temperature, Chemistry, Automation and so much more connected right to your smart home.</p>
                            <button className="tile__button"><a target='_blank' href="https://www.hayward-pool.ca/shop/en/canada/in-ground-product/can-automation-info" className="tile__link">Learn More</a></button>
                        </div>
                   </div>

                   <div className="equipment__tile">
                        <div className="tile__container">
                            <img src={Pump} alt="Hayward Variable Speed Pump" className='tile__img'/>
                            <h4 className="tile__category">Pump</h4>
                            <h2 className="tile__product">Hayward Variable Speed Pump</h2>
                            <p className="tile__description">Circulate your pool water more effeciently with a variable speed pump. Saving up to $1,500/year in operating costs over a single speed pump, while operating silently.</p>
                            <button className="tile__button"><a target='_blank' href="https://www.hayward-pool.ca/shop/en/canada/can-energy-calculator" className="tile__link">Savings</a></button>
                        </div>
                   </div>

                   <div className="equipment__tile">
                        <div className="tile__container">
                            <img src={Filter} alt="Hayward Cartridge Filter" className='tile__img'/>
                            <h4 className="tile__category">Filter</h4>
                            <h2 className="tile__product">Hayward Cartidge Filter</h2>
                            <p className="tile__description">SwimClear cartridge filters provide the most energy efficient flow and save up to 2,500 gallons of water per year by eliminating the need to backwash like a Sand Filter.</p>
                            <button className="tile__button"><a target="_blank" href="chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://www.hayward-pool.com.au/assets/documents/poolsaustralia/pdf/literature/swimclear-brochure.pdf" className="tile__link">Learn More</a></button>
                        </div>
                   </div>

                   <div className="equipment__tile">
                        <div className="tile__container">
                            <img src={Heater} alt="Hayward H-Series Gas Heater" className='tile__img'/>
                            <h4 className="tile__category">Heater</h4>
                            <h2 className="tile__product">Hayward H-Series Heater</h2>
                            <p className="tile__description">Hayward's patented technology offers the lowest cost of operation when compared to competitors' gas heaters. The H-Series heater heats your pool roughly 1&deg;F an hour.</p>
                            <button className="tile__button"><a target="_blank" href="chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://www.hayward-pool.ca/assets/documents/pools/pdf/literature/Universal-H-Series-Brochure-LITUHS20B.pdf" className="tile__link">Learn More</a></button>
                        </div>
                   </div>

                   <div className="equipment__tile">
                        <div className="tile__container">
                            <img src={SaltCell} alt="Polaris P945 Robotic Pool Cleaner" className='tile__img'/>
                            <h4 className="tile__category">Salt System</h4>
                            <h2 className="tile__product">Hayward Aquarite Salt Chlorinator</h2>
                            <p className="tile__description">Save over 50% compared to a chlorine pool with the #1 salt chlorinator in the world. With automatic chlorine production providing soft and clear water with no more red eyes or itchy skin.</p>
                            <button className="tile__button"><a target="_blank" href="https://www.hayward-pool.ca/shop/en/canada/in-ground-product/can-sanitization-info" className="tile__link">Learn More</a></button>
                        </div>
                   </div>

                   <div className="equipment__tile">
                        <div className="tile__container">
                            <img src={Cleaner} alt="Polaris P945 Robotic Pool Cleaner" className='tile__img'/>
                            <h4 className="tile__category">Robotic Cleaner</h4>
                            <h2 className="tile__product">Polaris P945 Robotic Cleaner</h2>
                            <p className="tile__description">Keep your weekends, with the fully robotic Polaris P945 Pool Cleaner. With its onboard power system and interchangable filters to clean your pool bottom, stairs and water line without lifting a finger.</p>
                            <button className="tile__button"><a target="_blank" href="https://www.polarispool.ca/en-ca/products/pool-cleaners/robotic-pool-cleaners/polaris-p945" className="tile__link">Learn More</a></button>
                        </div>
                   </div>

                </section>
                <section className="equipment__lighting">
                   
                   </section>
            </div>
    );
};

export default Equipment;